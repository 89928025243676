'use client';
import * as React from 'react';
import { TrackingMethods } from '@mentimeter/google-tracking';
import {
  Box,
  Button,
  ModalBody,
  ModalContainer,
  ModalRoot,
  ModalTrigger,
  type BoxT,
  type VariantT,
} from '@mentimeter/ragnar-ui';
import { H2, P } from '../ui/typography';
import SalesForceForm, { type FormValuesT } from './SalesForceForm';

// ChiliPiper concierge API signature
declare global {
  interface Window {
    ChiliPiper?: {
      submit: (
        subdomain: string,
        routerName: string,
        params: { lead: unknown },
      ) => void;
    };
  }
}

interface ContactUsModalT {
  onClick?: () => void;
  text?: string | undefined;
  variant?: VariantT;
  size?: 'large' | 'default' | 'compact';
}

/* Use this if a modal is wanted to open the form*/
export const ContactUs = ({
  onClick,
  text = 'Contact us',
  variant = 'tertiary',
  size = 'large',
}: ContactUsModalT) => {
  const [show, setShow] = React.useState(false);

  return (
    <ModalRoot open={show} onOpenChange={setShow}>
      <ModalTrigger>
        <Button
          variant={variant}
          size={size}
          onClick={() => {
            onClick?.();
            setShow(true);
          }}
        >
          {text}
        </Button>
      </ModalTrigger>
      <ModalContainer mr="space0" maxWidth={[374, 469]}>
        <ModalBody>
          <ContactUsForm p="space4" placement="cta" />
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
};

interface ContactUsFormProps extends BoxT {
  placement?: string;
}

/* Use this if only the form is wanted */
export const ContactUsForm = ({
  placement = 'hero',
  ...rest
}: ContactUsFormProps) => {
  const [hasSentForm, setHasSentForm] = React.useState(false);

  const onHasSentForm = React.useCallback(
    (data: FormValuesT) => {
      // send lead to ChiliPiper only if it's attached to website through <ChiliPiperScript /> tag
      // and only client-side
      window?.ChiliPiper?.submit(
        'mentimeter', // subdomain
        'enterprise-inbound-router', // router name
        { lead: data },
      );

      setHasSentForm(true);
      TrackingMethods.trackGenerateLead(placement, data.email);
    },
    [placement],
  );

  return (
    <Box borderRadius={1} overflow="hidden" {...rest}>
      <Box maxWidth="400px" alignItems="center">
        {hasSentForm ? (
          <>
            <H2>Thank you!</H2>
            {/* TODO Confirm sucess message */}
            <P textAlign="center" mb="space0">
              We will reach out to you shortly.
            </P>
          </>
        ) : (
          <SalesForceForm onHasSentForm={onHasSentForm} placement={placement} />
        )}
      </Box>
    </Box>
  );
};
