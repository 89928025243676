import type { UtmTagsT, UtmFirst, UtmLast } from './types';
import { FIRST_TOUCH_KEY, LAST_TOUCH_KEY } from './utmKeys';
import { getUtmParamsFromCookies } from './getUtmParamsFromCookies';

export const getUtmValues = (): UtmTagsT => {
  const utmParams = getUtmParamsFromCookies();
  // @ts-expect-error-auto TS(2322) FIXME: Type 'null' is not assignable to type 'UtmFirst'.
  if (!utmParams) return { utmFirst: null, utmLast: null };
  return {
    utmFirst: utmParams[FIRST_TOUCH_KEY] as UtmFirst,
    utmLast: utmParams[LAST_TOUCH_KEY] as UtmLast,
  };
};
