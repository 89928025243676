import type { AlignItemsProperty, Responsive } from '@mentimeter/ragnar-styled';
import { Box, type ButtonT } from '@mentimeter/ragnar-ui';
import { P } from 'src/ui/typography';
import { ContactUs } from 'src/components/ContactUs';
import { Button } from '.';

export interface ActionT extends ButtonT {
  description?: string | undefined;
  description_second_line?: string;
  alignItems?: Responsive<AlignItemsProperty> | undefined;
  descriptionColor?: string;
  modal?: 'enterprise' | undefined;
}

export const Action = ({
  alignItems = 'center',
  size = 'large',
  descriptionColor,
  modal,
  ...props
}: ActionT) => {
  if (modal === 'enterprise') {
    return <ContactUs variant="primary" text={props.children?.toString()} />;
  }

  return (
    <Box alignItems={alignItems}>
      <Button key={props.href} size={size} {...props} />
      {props.description && (
        <P
          fontSize={['87.5', '100']}
          color={descriptionColor ?? 'textWeaker'}
          mb="space0"
          mt="space2"
        >
          {props.description}
        </P>
      )}
      {props.description_second_line && (
        <P
          fontSize={['87.5', '100']}
          color={descriptionColor ?? 'textWeaker'}
          mb="space0"
          mt="space2"
        >
          {props.description_second_line}
        </P>
      )}
    </Box>
  );
};
