let fetcher: Promise<string>;
let emails: Set<string>;

const fetchEmails = () => {
  // allow last fetch to resolve
  if (fetcher) return fetcher;
  else {
    const emailsUrl = new URL('./uwanted-domains.txt', import.meta.url);
    return (fetcher = fetch(emailsUrl).then((res) => res.text()));
  }
};

const build = async () => {
  const emailList = await fetchEmails();
  if (emails) {
    // this `if`, coming after the fetchEmails, is important to avoid creating a new Set for each concurrent requests
    return emails;
  } else {
    emails = new Set(emailList.split('\n'));
    return emails;
  }
};

/**
 * Checks if an email is safe to use
 * @param email email to check
 * @returns  true if the email is safe, false otherwise
 */
export const isSafeEmail = async (email: string): Promise<boolean> => {
  const domain = email.substring(email.lastIndexOf('@') + 1);
  const emailList = await build();
  return !emailList.has(domain);
};
